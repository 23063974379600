import React from 'react';
import { Link } from 'react-router-dom'; // assuming React Router is set up
import './Footer.css';

function Footer() {

    return (
        <div className="footer">
            <div className='column'>
                <div>PikaPay Oy</div>
                <div>3329933-6</div>
            </div>
            <div className='column'>
                <div>
                    <a href="mailto:info@pikapay.fi" className="footer-link">info@pikapay.fi</a>
                </div>
                <div>
                    <Link to="/support" className="footer-link">Support</Link>
                </div>
            </div>
            <div className='column'>
                <div>
                    <Link to="/privacypolicy" className="footer-link">Privacy Policy</Link>
                </div>
                <div>
                    <Link to="/terms" className="footer-link">Terms of Service</Link>
                </div>
            </div>
            <div className='column'>
                <Link to="/jobs" className="footer-link">Careers</Link>
            </div>
            <div>©2024 PikaPay Oy</div>
        </div>
    );
}

export default Footer;
