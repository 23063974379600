import React from 'react';
import './LocationCard.css'; // External CSS file
import { FiMapPin } from 'react-icons/fi';

const LocationCard = ({ title, location, photoUrl }) => {
    if (!title) {
        return (
            <div className="location-card-container">
                <div className="coming-soon-card">
                    <div className="coming-soon-text">More coming soon</div>
                </div>
            </div>
        );
    }

    return (
        <div className="location-card-container">
            <div className="location-card" style={{
                backgroundImage: `url(${photoUrl})`,
            }}>
                <div className="location-info">
                    <div className="location-title">{title}</div>
                    <div className="location-location-container">
                        <FiMapPin className="location-icon" />
                        <div className="location-location">{location}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LocationCard;
