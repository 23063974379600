import React, { useContext } from 'react';
import './Business.css';
import { BigButton } from '../../components/buttons/Button';
import { FiArrowUpRight } from 'react-icons/fi';
import Footer from '../../components/footer/Footer';
import { MobileContext } from '../../MobileContext';

function Business() {
    const isMobile = useContext(MobileContext);

    return (
        <div className="container">
            <div className="contentSections">
                <LandingSection mobile={isMobile} />
                <ProductSection mobile={isMobile} />
                <ValueSection />
                <Footer />
            </div>
        </div>
    );
}



const LandingSection = ({ mobile }) => {
    const items = ["Opens your store in the app", "Chooses products", "Pays"];

    return (
        <div className="mainSection">
            <div className="leftColumn">
                <div className="textBlock">
                    <div className="textSection">
                        <div className="heading">
                            <span className="largeText">So your customers need to wait<br /></span>
                            {/*<span className="smallText"><br />(at worst, in line)<br /></span>*/}
                            <span className="largeText">before they can pay?</span>
                        </div>
                        <div className="businessDescription">
                            <span className="semiBold">Not anymore with PikaPay!</span>
                            <span> </span>
                            <span>You only need to worry about what happens after a successful payment. PikaPay takes care of everything before that, </span>
                            <span className='semiBold'>right on your customers' phones.</span>
                        </div>
                    </div>
                    <BigButton text="Contact sales" linkTo="oskari@pikapay.fi" icon={<FiArrowUpRight size={24} />} />
                </div>
                {!mobile && (<div className="customerFlow">
                    <span>Customer using PikaPay<br /></span>
                    <ol className="custom-list">
                        {items.map((item, index) => (
                            <li key={index}>
                                <span className="list-number">{index + 1}.</span>
                                <span className="list-text">{item}</span>
                            </li>
                        ))}
                    </ol>
                    <span>What happens after that, depends if:</span>
                </div>)}
            </div>
            <img className="mainImage" src="images/arvo.png" alt="" />
        </div>
    );
}

const ProductSection = ({ mobile }) => {
    const items = ["Opens your store in the app", "Chooses products", "Pays"];

    return (
        <>
            {mobile && (<div className="customerFlow">
                <span>Customer using PikaPay<br /></span>
                <ol className="custom-list">
                    {items.map((item, index) => (
                        <li key={index}>
                            <span className="list-number">{index + 1}.</span>
                            <span className="list-text">{item}</span>
                        </li>
                    ))}
                </ol>
                <span>What happens after that, depends if:</span>
            </div>)}
            <div className="optionsSection">

                <div className="optionBlock">
                    <div className='optionTitle'>
                        <div className="optionHeading">A.&nbsp;</div>
                        <div className="optionText">You have the product ready on purchase</div>
                    </div>
                    <div className="optionDescription">Shops, buffet restaurants etc.</div>
                </div>
                <div className="orText">or</div>
                <div className="optionBlock">
                    <div className='optionTitle'>
                        <div className="optionHeading">B.&nbsp;</div>
                        <div className="optionText">You prepare the product after order</div>
                    </div>
                    <div className="optionDescription">Bars, fast food restaurants etc.</div>
                </div>
            </div>
            <div className="scanSystemSection">
                <div className='systemTitle'>
                    <div className="systemHeading">A.&nbsp;</div>
                    <div className="systemText">Scan system</div>
                </div>
                <div className="scanSystemContent">
                    <div className="emptyDivider" />
                    <div className="phoneBlock">
                        <div>Customer’s Phone</div>
                        <img className="phoneImage" src="images/QRCodeReceipt.png" alt="Scannable receipt" />
                    </div>
                    <div className="scanText">Customer’s purchase can be verified by scanning the QR-code in the recipe.</div>
                    <div className="scannerBlock">
                        <div>Scanner</div>
                        <img className="scannerImage" src="images/Scanner.png" alt="Scanner" />
                    </div>
                    <div className="emptyDivider"></div>
                </div>
            </div>
            <div className="orderSystemSection">
                <div className='systemTitle'>
                    <div className="systemHeading">B.&nbsp;</div>
                    <div className="systemText">Order system</div>
                </div>
                <div className="orderSystemContent">
                    <div className="phoneBlock">
                        <div>Customer’s Phone</div>
                        <img className="phoneImage" src="images/OrderReceipt.png" alt="Order receipt" />
                    </div>
                    <div className="dashboardBlock">
                        <div>Dashboard</div>
                        <img className="dashboardImage" src="images/Orders.png" alt="Dashboard orders" />
                    </div>
                </div>
                <div className="orderText">Orders can be easily managed from the dashboard. The order system supports both delivery to numbered tables and pickup from the counter.</div>
            </div>
            <div className="productFooterSection">
                <div className="productFooterBlock">
                    <div className="productFooterHeading">More about the dashboard</div>
                    <div className="productFooterText">
                        Regardless of which system suits your needs the best, you will find great use in our dashboard:
                        <ul>
                            <li>Manage products easily.</li>
                            <li>Various tools for insights and reporting.</li>
                            <li>Helping you in problematic situations.</li>
                        </ul>
                    </div>
                </div>
                <div className="productFooterSide">
                    <div className="productFooterHeading">Offering student discounts?</div>
                    <div className="productFooterText">Your customers wont need their student card.<br /> We have integrated student verification.</div>
                </div>
            </div>
        </>
    );
}

function ValueSection() {
    return <>
        <div className="finalSection">
            <div className="finalHeading">What’s the value?</div>
            <div className="valueContent">
                <div className="valueBlock">
                    <div className="valueHeading">You</div>
                    <ul className="valueText">
                        <li>need less POS systems.</li>
                        <li>can allocate your cashiers elsewhere.</li>
                        <li>can serve more customers quicker.</li>
                        <li>get valuable data.</li>
                        <li>offer better customer experience.</li>
                        <li>increase income, reduce costs.</li>
                    </ul>
                </div>

                <div className="valueBlock">
                    <div className="valueHeading">Your customers</div>
                    <ul className="valueText">
                        <li>don’t need to wait in line.</li>
                        <li>save time.</li>
                        <li>can see all the products on their phones.</li>
                        <li>won't need their wallets.</li>
                        <li>receive receipts on their phones.</li>
                        <li>can follow the status of their orders.</li>
                    </ul>
                </div>
            </div>
        </div>
    </>
}

export default Business;