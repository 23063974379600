import Carousel from "./components/Carousel";
import './Locations.css';

const cardsData = [
    { title: null, location: "", photoUrl: "}" },
    { title: 'Restaurant YOLO', location: 'Lappeenranta', photoUrl: 'https://www.aalef.fi/wp-content/uploads/2022/12/hero-aalef-1-1500x500.jpg' },
    { title: 'Restaurant Arvo', location: 'Tampere', photoUrl: `${process.env.PUBLIC_URL}/images/arvonsafkat.jpg` },
    { title: 'LUT Buffet', location: 'Lappeenranta', photoUrl: 'https://www.kampusravintolat.fi/wp-content/uploads/LutBuffet3.jpg' },
    { title: 'Restaurant Skinnarila', location: 'Lappeenranta', photoUrl: 'https://www.kampusravintolat.fi/wp-content/uploads/Skinnarila.jpg' },
    { title: null, location: "", photoUrl: "}" },
    { title: "August Restaurant", location: "Vaasa", photoUrl: "https://augustrestaurant.fi/wp-content/uploads/2023/02/edward-howell-vvUy1hWVYEA-unsplash.jpg" },
    { title: 'Restaurant Arvo', location: 'Tampere', photoUrl: `${process.env.PUBLIC_URL}/images/arvonsafkat.jpg` },

    //{ title: 'Cafe Lea', location: 'Tampere', photoUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/43/Bar-P1030319.jpg/640px-Bar-P1030319.jpg' },
    // Add more cards as needed
];

function Locations() {
    return (
        <div className="locations-content">
            <Carousel cards={cardsData} />
        </div>
    );
}

export default Locations;