import React, { useContext, useEffect, useRef } from 'react';
import LocationCard from './LocationCard';
import './Carousel.css';
import { MobileContext } from '../../../MobileContext';

const Carousel = ({ cards }) => {

    const isMobile = useContext(MobileContext);

    const wrapperRef = useRef(null);
    const velocityRef = useRef(0); // Stores scroll velocity
    const isDraggingRef = useRef(false); // Tracks dragging state


    const carouselCardsTop = isMobile
        ? cards.slice(0, cards.length / 3)
        : cards.slice(0, cards.length / 2);

    const carouselCardsMiddle = isMobile
        ? cards.slice(cards.length / 3, (cards.length * 2) / 3)
        : cards.slice(cards.length / 2);

    const carouselCardsBottom = isMobile
        ? cards.slice((cards.length * 2) / 3)
        : [];

    const carouselCardsTopRepeated = [...carouselCardsTop, ...carouselCardsTop, ...carouselCardsTop];
    const carouselCardsMiddleRepeated = [...carouselCardsMiddle, ...carouselCardsMiddle, ...carouselCardsMiddle];
    const carouselCardsBottomRepeated = isMobile ? [...carouselCardsBottom, ...carouselCardsBottom, ...carouselCardsBottom] : [];

    const scrollSpeed = 4; // Higher scroll speed for better responsiveness
    const dragSensitivity = 1; // Slightly lower drag sensitivity for smoother dragging

    useEffect(() => {
        const wrapper = wrapperRef.current;
        let lastTime = performance.now();
        let lastPosition = wrapper.scrollLeft;

        // Start in the middle of the carousel
        wrapper.scrollLeft = wrapper.scrollWidth / 3;

        // Function to calculate the scroll velocity
        const trackVelocity = () => {
            const currentTime = performance.now();
            const currentPosition = wrapper.scrollLeft;
            const deltaTime = currentTime - lastTime;
            const deltaPosition = currentPosition - lastPosition;

            velocityRef.current = deltaPosition / deltaTime; // Calculate velocity

            lastPosition = currentPosition;
            lastTime = currentTime;
        };

        // Function to handle seamless reset of scroll position
        const resetScrollPosition = () => {

            const maxScrollLeft = wrapper.scrollWidth / 3;

            if (wrapper.scrollLeft <= 1) {
                wrapper.style.scrollBehavior = 'auto'; // Disable smooth scroll to prevent visible jump
                wrapper.scrollLeft = maxScrollLeft + velocityRef.current * scrollSpeed; // Reset to the middle
                wrapper.style.scrollBehavior = 'smooth'; // Re-enable smooth scrolling
            }

            if (wrapper.scrollLeft >= maxScrollLeft * 2 - 1) {
                wrapper.style.scrollBehavior = 'auto'; // Disable smooth scroll
                wrapper.scrollLeft = maxScrollLeft + velocityRef.current * scrollSpeed; // Reset to the middle
                wrapper.style.scrollBehavior = 'smooth'; // Re-enable smooth scrolling
            }
        };

        const handleScroll = () => {
            trackVelocity(); // Track velocity on every scroll event
            resetScrollPosition(); // Seamlessly reset position if necessary

        };

        // Add event listener for scroll events
        wrapper.addEventListener('scroll', handleScroll);

        return () => {
            wrapper.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Handle dragging start
    const handleMouseDown = (event) => {
        const wrapper = wrapperRef.current;
        isDraggingRef.current = true;
        wrapper.startX = event.pageX - wrapper.offsetLeft;
        wrapper.scrollLeftStart = wrapper.scrollLeft;
        wrapper.style.scrollBehavior = 'auto'; // Disable smooth scrolling during dragging
    };

    // Handle dragging movement
    const handleMouseMove = (event) => {
        const wrapper = wrapperRef.current;
        if (!isDraggingRef.current) return;
        const x = event.pageX - wrapper.offsetLeft;
        const walk = (x - wrapper.startX) * dragSensitivity; // Adjust drag sensitivity
        wrapper.scrollLeft = wrapper.scrollLeftStart - walk;
    };

    // Handle drag end and reset smooth scrolling
    const handleMouseUpOrLeave = () => {
        isDraggingRef.current = false;
        const wrapper = wrapperRef.current;
        wrapper.style.scrollBehavior = 'smooth'; // Re-enable smooth scrolling after dragging
    };

    // Handle mouse wheel scrolling
    const handleWheel = (event) => {
        const wrapper = wrapperRef.current;
        event.preventDefault();
        wrapper.scrollLeft += event.deltaY * scrollSpeed; // Apply scroll speed
    };

    // Add wheel event listener for mouse scrolling
    useEffect(() => {
        const wrapper = wrapperRef.current;
        wrapper.addEventListener('wheel', handleWheel);

        return () => {
            wrapper.removeEventListener('wheel', handleWheel);
        };
    }, []);

    return (
        <div className="carousel-container">
            <div className="gradient gradient-left"></div>
            <div
                className="carousel-wrapper"
                ref={wrapperRef}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUpOrLeave}
                onMouseLeave={handleMouseUpOrLeave}
            >
                <div className="carousel-row row1">
                    {carouselCardsTopRepeated.map((card, index) => (
                        <LocationCard
                            key={`row1-${index}`}
                            title={card.title}
                            location={card.location}
                            photoUrl={card.photoUrl}
                        />
                    ))}
                </div>
                <div className="carousel-row row2">
                    {carouselCardsMiddleRepeated.map((card, index) => (
                        <LocationCard
                            key={`row2-${index}`}
                            title={card.title}
                            location={card.location}
                            photoUrl={card.photoUrl}
                        />
                    ))}
                </div>
                {isMobile && (<div className={`carousel-row row3`}>
                    {carouselCardsBottomRepeated.map((card, index) => (
                        <LocationCard
                            key={`row3-${index}`}
                            title={card.title}
                            location={card.location}
                            photoUrl={card.photoUrl}
                        />
                    ))}
                </div>)}
            </div>
            <div className="gradient gradient-right"></div>
        </div>
    );
};

export default Carousel;
