import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy">
            <h1>Privacy Policy</h1>
            <p>
                At PikaPay Oy (FI33299336) we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines our practices for collecting, using, and disclosing your personal data.
            </p>

            <h2>Information we collect</h2>
            <p>
                We may collect personal information that you voluntarily provide to us when you interact with our website, products, or services. This information may include your name, email address, mailing address, phone number, and other information that you choose to share with us.
            </p>

            <h2>How we use your information</h2>
            <ul>
                <li>Provide you with our products and services</li>
                <li>Communicate with you about your account or our products and services</li>
                <li>Improve our products and services</li>
                <li>Customize your experience on our website</li>
                <li>Send you marketing communications that we believe may be of interest to you</li>
                <li>Comply with legal or regulatory obligations</li>
            </ul>

            <h2>Sharing your information</h2>
            <p>
                We may share your personal information with third-party service providers that help us with our business operations, such as payment processors, marketing providers, and website hosts. We may also share your information with government or law enforcement authorities when required by law or as necessary to protect our legal rights.
            </p>

            <h2>Cookies and other technologies</h2>
            <p>
                We may use cookies and other technologies to collect information about your use of our website, such as your IP address, browser type, and pages visited. This information may be used to personalize your experience and improve our products and services.
            </p>

            <h2>Security</h2>
            <p>
                We take reasonable measures to protect your personal information from unauthorized access, disclosure, or use. However, no method of transmission over the internet or method of electronic storage is completely secure, and we cannot guarantee the absolute security of your information.
            </p>

            <h2>Your rights</h2>
            <p>
                You have the right to access, modify, or delete your personal information that we have collected. You may also object to the processing of your personal information or request that we limit the use of your information. To exercise these rights, please contact us at <a href="mailto:info@pikapay.fi">info@pikapay.fi</a>.
            </p>

            <h2>Updates to this policy</h2>
            <p>
                We may update this Privacy Policy from time to time. The updated policy will be posted on our website, and we encourage you to review it periodically.
            </p>

            <h2>Contact us</h2>
            <p>
                If you have any questions or concerns about our Privacy Policy, please contact us at: <a href="mailto:info@pikapay.fi">info@pikapay.fi</a>.
            </p>

            <p>
                We will do our best to respond to your inquiries within a reasonable time frame. Thank you for entrusting your personal information to PikaPay Oy. We are committed to maintaining your privacy and keeping your information secure.
            </p>
        </div>
    );
};

export default PrivacyPolicy;
