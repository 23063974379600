import React from 'react';
import './Terms.css';

const Terms = () => {
    return (
        <div className="terms-of-service">
            <h1>Terms of Service</h1>
            <p>
                Please read these terms of service ("Agreement") carefully before using this website ("Site") operated by PikaPay Oy ("us", "we", or "our"). This Agreement sets forth the legally binding terms and conditions for your use of the Site at www.pikapay.fi.
            </p>

            <p>
                By accessing or using the Site in any manner, including, but not limited to, visiting or browsing the Site or contributing content or other materials to the Site, you agree to be bound by this Agreement.
            </p>

            <h2>Intellectual Property</h2>
            <p>
                The Site and its original content, features, and functionality are owned by PikaPay Oy and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
            </p>

            <h2>Termination</h2>
            <p>
                We may terminate your access to the Site, without cause or notice, which may result in the forfeiture and destruction of all information associated with you. All provisions of this Agreement that, by their nature, should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
            </p>

            <h2>Links to Other Sites</h2>
            <p>
                Our Site may contain links to third-party sites that are not owned or controlled by PikaPay Oy. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party sites or services. We strongly advise you to read the terms and conditions and privacy policy of any third-party site that you visit.
            </p>

            <h2>User Conduct</h2>
            <p>
                You agree that you will not use the Site for any purpose that is unlawful or prohibited by this Agreement. You must not misuse the Site in any way, including but not limited to, hacking or attempting to hack, introducing viruses or other malicious code, or interfering with or disrupting the operation of the Site.
            </p>

            <p>
                You also agree that you will not use the Site to:
            </p>
            <ul>
                <li>Upload or transmit any content that is unlawful, harmful, threatening, abusive, harassing, defamatory, obscene, or racially or ethnically offensive;</li>
                <li>Infringe any intellectual property rights, privacy rights, or other rights of any third party;</li>
                <li>Impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with any person or entity;</li>
                <li>Collect or store personal data about other users without their consent;</li>
                <li>Promote or advertise any products or services without our prior written consent.</li>
            </ul>

            <h2>Disclaimer of Warranties</h2>
            <p>
                The Site is provided on an "as is" and "as available" basis. We make no representations or warranties of any kind, express or implied, as to the operation of the Site or the information, content, materials, or products included on the Site. You expressly agree that your use of the Site is at your sole risk.
            </p>

            <h2>Limitation of Liability</h2>
            <p>
                In no event shall PikaPay Oy or its directors, officers, employees, agents, or affiliates be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of the Site, whether or not such damages were foreseeable and whether or not PikaPay Oy was advised of the possibility of such damages.
            </p>

            <h2>Indemnification</h2>
            <p>
                You agree to indemnify, defend, and hold harmless PikaPay Oy and its directors, officers, employees, agents, and affiliates from and against any and all claims, damages, losses, liabilities, costs, and expenses (including reasonable attorneys' fees) arising out of or in connection with your use of the Site, your breach of this Agreement, or your violation of any law or the rights of a third party.
            </p>

            <h2>Entire Agreement</h2>
            <p>
                This Agreement constitutes the entire agreement between you and PikaPay Oy regarding the use of the Site, superseding any prior agreements between you and us regarding the Site. If any provision of this Agreement is found to be invalid or unenforceable, the remaining provisions shall be enforced to the fullest extent possible, and the remaining provisions of the Agreement shall remain in full force and effect.
            </p>

            <h2>Governing Law</h2>
            <p>
                This Agreement and any dispute arising out of or in connection with this Agreement shall be governed by and construed in accordance with the laws of Finland, without regard to its conflict of law provisions.
            </p>

            <h2>Changes to This Agreement</h2>
            <p>
                We reserve the right, at our sole discretion, to modify or replace these Terms of Service by posting the updated terms on the Site. Your continued use of the Site after any such changes constitutes your acceptance of the new Terms of Service.
            </p>

            <h2>Contact Us</h2>
            <p>
                If you have any questions about this Agreement, please contact us at <a href="mailto:info@pikapay.fi">info@pikapay.fi</a>.
            </p>
        </div>
    );
};

export default Terms;
