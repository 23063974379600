import React from 'react';
import './Jobs.css';
import Footer from '../../components/footer/Footer';

const Jobs = () => {
    const positions = [
        {
            title: "Asiakaspalveluhenkinen promoottori",
            description: `Etsimme eri puolille Suomea henkilöitä kertomaan lounasravintoloiden asiakkaille uuden, nopeamman maksutavan olemassaolosta. 
            
            Työ on kevyttä asiakaspalveluhenkistä promootiota, missä pääpaino on sovelluksen tunnettuuden lisääminen ravintolan asiakkaiden keskuudessa. Lisäksi käyttäjien lisääntyessä osa työstä on asiakaspalautteen keräämistä tuotekehitystä varten. 

            Työtä on arkisin lounasaikaan (joustavasti klo 10:30-15 välissä). Tuntipalkka työsuhteen alussa on 15 €/h ja yhden ravintolan promootion kesto on noin 2-4 viikkoa. Ravintoloita voi olla paikkakunnalla useita. 
            
            Haluamme löytää ennen kaikkea hyviä tyyppejä, jotka haluavat oppia itsenäiseen työntekoon ja pyrkivät vilpittömästi palvelemaan sekä sovelluksen käyttäjiä että ravintolahenkilökuntaa. 
            
            Lisätietoja: Tuukka Järvinen, COO, 040 936 3390, `,
            email: "jobs@pikapay.fi"
        },
        {
            title: "Customer-Oriented Promoter",
            description: `We are looking for people across Finland to inform customers at lunch restaurants about a new, faster payment method. 
            
            The job is light customer service-oriented promotion, focusing on increasing the app's visibility among restaurant customers. Additionally, as the user base grows, part of the job will involve gathering customer feedback for product development.
            
            The work is weekdays during lunch hours (flexibly between 10:30 AM and 3 PM). The starting hourly wage is €15, and the promotion at one restaurant lasts about 2-4 weeks. There may be several restaurants in one locality.
            
            We are primarily looking for good people who want to learn to work independently and are sincerely dedicated to serving both app users and restaurant staff.
            
            For more information, contact Tuukka Järvinen, COO, +358 40 936 3390, `,
            email: "jobs@pikapay.fi"
        }
    ];

    return (
        <div className="jobs-container">
            <section className="about-section">
                <p>At PikaPay, we believe in people above all else.</p>
                <p>
                    Every employee should be given an equal opportunity to showcase their abilities in an environment that promotes entrepreneurship,
                    provides constant support on a daily basis, and offers more than just the basic tools to advance through coaching and training.
                </p>
                <p>
                    We want to be a company that employees remember as a place where they were able to show their potential,
                    where their manager was genuinely present and helped them learn important things for their future.
                </p>
            </section>

            <section className="jobs-section">
                <h2>Open positions</h2>

                {positions.map((position, index) => (
                    <div className="job-opening">
                        <h3>{position.title}</h3>
                        <p>
                            {position.description}
                            <a href={`mailto:${position.email}`}>{position.email}</a>
                        </p>
                    </div>))}

                <div className="open-application">
                    <h3>Avoin hakemus</h3>
                    <p>
                        Haluatko oppia, millaista on olla töissä kasvuyrityksessä? Osaatko jotain, millä uskot
                        olevan arvoa PikaPayn kaltaiselle yritykselle?
                    </p>
                    <p>
                        Ota puhelin rohkeasti käteen ja soita Tuukalle. Jutellaan ja mietitään, millaista
                        yhteistyötä voisimme tehdä.
                    </p>
                    <p>
                        Yhteystiedot: Tuukka Järvinen, COO, 040 936 3390, <a href="mailto:jobs@pikapay.fi">jobs@pikapay.fi</a>
                    </p>
                </div>

                <div className="open-application">
                    <h3>Open application</h3>
                    <p>
                        Do you want to learn what it's like to work at a growing company? Do you have skills that you think would be valuable to a company like PikaPay?
                    </p>
                    <p>
                        Pick up the phone and call Tuukka boldly. Let’s chat and think about what kind of collaboration we could do together.
                    </p>
                    <p>
                        Contact: Tuukka Järvinen, COO, 040 936 3390, <a href="mailto:jobs@pikapay.fi">jobs@pikapay.fi</a>
                    </p>
                </div>
            </section>



            <Footer />
        </div>
    );
};

export default Jobs;
