import React from 'react';
import { FaEnvelope, FaTelegramPlane } from 'react-icons/fa'; // Import icons
import './Support.css';

const Support = () => {
    return (
        <div className="support-container">
            <h1 className="support-heading">Support</h1>
            <p className="support-text">
                We're here to help! You can reach us through the following channels:
            </p>
            <div className="support-contact-info">
                <div className="support-contact-item">
                    <FaEnvelope className="support-icon" />
                    <h2 className="support-title">Email:</h2>
                    <a href="mailto:support@pikapay.fi" className="support-link">
                        support@pikapay.fi
                    </a>
                </div>
                <div className="support-contact-item">
                    <FaTelegramPlane className="support-icon" />
                    <h2 className="support-title">Telegram:</h2>
                    <a
                        href="https://t.me/@pikapay_bot"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="support-link"
                    >
                        @pikapay_bot
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Support;
