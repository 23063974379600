import React, { useState } from 'react';
import './About.css';
import Footer from '../../components/footer/Footer';
import { IoLogoInstagram, IoLogoLinkedin } from 'react-icons/io';
import { IoLogoTiktok } from 'react-icons/io5';

const teamMembers = [
    {
        name: "Oskari",
        role: "CEO",
        imgSrc: "images/Oskari.jpeg",
        details: {
            fullName: "Oskari Hervonen",
            roleDetails: "Chief Executive Officer, Founder",
            email: "oskari@pikapay.fi",
            linkedin: "https://www.linkedin.com/in/oskari-hervonen-146473176/",
            story: "Oskari is responsible for overseeing PikaPay's vision and growth...",
        },
    },
    {
        name: "Tuukka",
        role: "COO",
        imgSrc: "images/Tuukka.jpg",
        details: {
            fullName: "Tuukka Järvinen",
            roleDetails: "Chief Operating Officer, Partner",
            email: "tuukka@pikapay.fi",
            linkedin: "https://www.linkedin.com/in/tuukkajarvinen/",
            story: "Tuukka oversees operations and ensures day-to-day smooth functioning...",
        },
    },
    {
        name: "Miika",
        role: "CTO",
        imgSrc: "images/Miika.jpeg",
        details: {
            fullName: "Miika Huttunen",
            roleDetails: "Chief Technology Officer, Partner",
            email: "miika@pikapay.fi",
            linkedin: "https://www.linkedin.com/in/miika-huttunen/",
            story: "Miika is responsible for all things tech at PikaPay...",
        },
    },
    {
        name: "Samuli",
        role: "HBD",
        imgSrc: "images/Samuli.jpg",
        details: {
            fullName: "Samuli Saarinen",
            roleDetails: "Head of Business Design, Partner",
            email: "samuli@pikapay.fi",
            linkedin: "https://www.linkedin.com/in/samuli-j-a-saarinen/",
            /*introduction: `Samuli is responsible for design, customer requirements, and the overall experience of our products at PikaPay.\n
                    Prior to joining PikaPay, Samuli created games and social platforms, which instilled in him a philosophy: `,
            quote: `"Meeting expectations isn’t enough. Expectations must be exceeded. In addition to fulfilling requirements excellently,
                    we need to offer things the customer didn’t know they wanted but will end up loving."`*/
        },
    },
];

const TeamMember = ({ name, role, imgSrc, onClick }) => {
    return (
        <div className="teamMember" onClick={onClick}>
            <img className="teamMemberImg" src={imgSrc} alt={`${name}'s portrait`} />
            <div className="teamMemberInfo">
                <span className="teamMemberName">{name}</span><br />
                <span className="teamMemberRole">{role}</span>
            </div>
        </div>
    );
};

const FeaturedMember = ({ member }) => {
    if (!member) return null;

    return (
        <div className="featuredMemberSection">
            <img className="featuredMemberImg" src={member.imgSrc} alt={member.details.fullName} />
            <div className="featuredMemberDetails">
                <div className="featuredMemberName">{member.details.fullName}</div>
                <div className="featuredMemberRole">{member.details.roleDetails}</div>
                <div className="featuredMemberContact">
                    <div className="contactDetail">{member.details.email}</div>
                    <a href={member.details.linkedin} className="contactDetail" target="_blank" rel="noopener noreferrer">
                        LinkedIn
                    </a>

                </div>

            </div>
            <div className="featuredMemberStory">
                <div className="mediumText">{member.details.introduction}</div>
                <div className="quote">
                    <span>{member.details.quote}</span>
                </div>
            </div>
        </div>
    );
};

const About = () => {
    const [featuredMemberIndex, setFeaturedMemberIndex] = useState(null);

    return (
        <div className="content">
            <div className="introSection">
                <div className="description">
                    <span className="boldText">PikaPay Oy&nbsp;</span>
                    <span className="mediumText">
                        is a fintech startup from Finland, developing innovative solutions to make payments effortless and faster.
                        <br /><br /><span className="boldText">PikaPay mobile app</span> is basically a personal cashier in your pocket,
                        allowing you to order and pay independently.
                    </span>
                </div>
                <div className="quote">“Can you imagine that people are waiting in line to pay when they could just pay.”</div>
                {/* 
<div className="introduction">
    <span className="mediumText">
        PikaPay’s story started in late 2022 when its current CEO, Oskari, met other founders in a startup idea accelerator organized by LUT Entrepreneurship Society.<br /><br />
        Oskari got them excited about his idea and so these young university students became startup entrepreneurs.<br /><br />
        After that, PikaPay has seen great growth, won multiple pitching competitions, and gained its position as one of the most promising fintech startups in Finland.
    </span>
</div>
*/}
            </div>

            <div className="teamSection">
                {teamMembers.map((member, index) => (
                    <TeamMember
                        key={index}
                        name={member.name}
                        role={member.role}
                        imgSrc={member.imgSrc}
                        onClick={() => setFeaturedMemberIndex(index)}
                    />
                ))}
            </div>

            <FeaturedMember member={teamMembers[featuredMemberIndex]} />

            <div className="socialSection">
                <a className="socialLink" href="https://www.linkedin.com/company/pikapayofficial/" target="_blank" rel="noopener noreferrer">
                    <IoLogoLinkedin size={60} />
                </a>
                <a className="socialLink" href="https://www.instagram.com/pikapayofficial/" target="_blank" rel="noopener noreferrer">
                    <IoLogoInstagram size={60} />
                </a>
                <a className="socialLink" href="https://www.tiktok.com/@pikapayofficial" target="_blank" rel="noopener noreferrer">
                    <IoLogoTiktok size={50} />
                </a>
            </div>

            <Footer />
        </div >
    );
};

export default About;
