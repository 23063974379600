import React from 'react';
import { Routes, Route, useLocation, BrowserRouter as Router } from 'react-router-dom';
import Navbar from './components/nav/Navbar';
import BackgroundVideo from './components/video/BackgroundVideo';
import Locations from './pages/locations/Locations';
import './App.css';
import Business from './pages/business/Business';
import About from './pages/about/About';
import Download from './pages/download/Download';
import PrivacyPolicy from './pages/legal/PrivacyPolicy';
import Terms from './pages/legal/Terms';
import { MobileProvider } from './MobileContext';
import Jobs from './pages/jobs/Jobs';
import Support from './pages/legal/Support';

function Layout() {
  const location = useLocation();

  return (
    <div id="root">
      <div className="container">
        {location.pathname === '/' && <BackgroundVideo />}
        <Navbar />
        <div className="content">
          <Routes>
            <Route path="/" exact element={<BackgroundVideo />} />
            <Route path="/locations" element={<Locations />} />
            <Route path="/business" element={<Business />} />
            <Route path="/about" element={<About />} />
            <Route path="/download" element={<Download />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/jobs" element={<Jobs />} />
            <Route path="/support" element={<Support />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

function App() {
  return (

    <MobileProvider>
      <Router>
        <Layout />
      </Router>
    </MobileProvider>

  );
}

export default App;

