import React from "react";
import './Button.css';
import { Link } from "react-router-dom";

export const BigButton = ({ text, icon, onClick, linkTo }) => {
    return (
        <Link to={linkTo}>
            <button className="big-button" onClick={onClick}>
                <div className="big-button-content">
                    <span>{text}</span>
                    {icon && <span
                        className="big-button-icon">{icon}</span>}
                </div>
            </button>
        </Link>
    );
}

export const MobileButton = ({ text, icon, onClick, linkTo }) => {
    return (
        <Link to={linkTo}>
            <button className="mobile-button" onClick={onClick}>
                <div className="mobile-button-content">
                    <span>{text}</span>
                    {icon && <span
                        className="mobile-button-icon">{icon}</span>}
                </div>
            </button>
        </Link>
    );
}

