import React from 'react';
import './Download.css';
import { AppStoreButton, GooglePlayButton } from 'react-mobile-app-button';
import Footer from '../../components/footer/Footer';

function Download() {
    return (
        <>
            <div className="download-container">
                <div className="download-content">
                    <h1>Download the app</h1>
                    <p>Make your life easier with PikaPay today!</p>
                    <div className="download-buttons">
                        <GooglePlayButton
                            store="android"
                            url="https://play.google.com/store/apps/details?id=fi.pikapay.pikapay"
                            className='download-button'
                            linkProps={{ title: 'Google Play' }}
                        />
                        <AppStoreButton
                            store="ios"
                            url="https://apps.apple.com/us/app/pikapay/id6445927385"
                            className='download-button'
                            theme='dark'
                            linkProps={{ title: 'App Store' }}
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Download;