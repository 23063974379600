import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import PikaPayIcon from "../../assets/PP_wordmark_white.svg";
import { FiX, FiMenu, FiArrowUpRight } from "react-icons/fi";
import { BigButton } from "../buttons/Button";
import { MobileContext } from "../../MobileContext";

function Navbar() {
    const [click, setClick] = useState(false);
    const button = !useContext(MobileContext);


    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    useEffect(() => {
        if (window.innerWidth > 960) {
            setClick(false);
        }
    }, []);

    return (
        <>
            <nav className="navbar">
                <div className="navbar-container">
                    <Link to="/" className="navbar-logo">
                        <img src={PikaPayIcon} alt="PikaPay" style={{ width: '112px', height: 'auto' }} />
                    </Link>
                    <ul className={click ? "nav-menu active" : "nav-menu"}>
                        <li className="nav-item">
                            <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                                Home
                            </Link>
                        </li>
                        <li className="nav-item" onClick={closeMobileMenu}>
                            <Link to="/locations" className="nav-links">
                                Locations
                            </Link>
                        </li>
                        <li className="nav-item" onClick={closeMobileMenu}>
                            <Link to="/business" className="nav-links">
                                Business
                            </Link>
                        </li>
                        <li className="nav-item" onClick={closeMobileMenu}>
                            <Link to="/about" className="nav-links">
                                About us
                            </Link>
                        </li>
                        <li className="nav-item" onClick={closeMobileMenu}>
                            <Link to="/download" className="nav-links-big">
                                Download
                            </Link>
                        </li>
                    </ul>
                    <div className="spacer" />
                    <div className="menu-icon" onClick={handleClick}>
                        {click ? <FiX className="icon-button" /> : <FiMenu className="icon-button" />}
                    </div>
                    {button && <BigButton text="Download" icon={<FiArrowUpRight size={24} />} linkTo={"/download"} />}
                </div>
            </nav >
        </>
    );
}

export default Navbar;