import React, { useRef, useState, useEffect } from 'react';
import './BackgroundVideo.css'; // Import the styles


function BackgroundVideo() {
    const videoRef = useRef(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 960);
    //const goingToPlayRef = useRef(false);
    //const [isPaused, setIsPaused] = useState(false);
    //const [currentTime, setCurrentTime] = useState(0);
    //const [duration, setDuration] = useState(0);
    //const [soundOn, setSoundOn] = useState(false);

    useEffect(() => {
        // Function to check window width
        const handleResize = () => {
            setIsMobile(window.innerWidth < 960);
        };

        // Attach event listeners for window resize
        window.addEventListener('resize', handleResize);

        // Clean up event listeners when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const video = videoRef.current;
        video.play().catch(error => console.error("Error trying to play the video: ", error));

        const handleKeydown = (event) => {
            if (event.code === 'Space') {
                event.preventDefault(); // Prevent default spacebar action
                if (video.paused) {
                    console.log("Playing video");
                    video.play();
                    //setIsPaused(false);
                } else {
                    console.log("Pausing video");
                    video.pause();
                    //setIsPaused(true);
                }
            } /*else if (event.code === 'KeyM') {
                    event.preventDefault(); // Prevent default action for 'M'
                    video.muted = !video.muted;
                    setSoundOn(!video.muted);
                    console.log("Mute toggled:", video.muted);
                }*/
        };

        // Attach event listeners for both keydown and scroll
        window.addEventListener('keydown', handleKeydown);
        //window.addEventListener('wheel', handleScroll, { passive: false });

        // Clean up event listeners when the component unmounts
        return () => {
            //video.removeEventListener('loadedmetadata', handleLoadedMetadata);
            // video.removeEventListener('timeupdate', handleTimeUpdate);
            window.removeEventListener('keydown', handleKeydown);
            //window.removeEventListener('wheel', handleScroll);
        };



        // Function to check window width

        // Update the video duration when the metadata is loaded
        /*const handleLoadedMetadata = () => {
            console.log("Video duration:", video.duration); // Debugging output
            setDuration(video.duration);
        };

        // Update currentTime state when the time updates
        const handleTimeUpdate = () => {
            setCurrentTime(video.currentTime);
        };
        

        // Set up event listeners
        video.addEventListener('loadedmetadata', handleLoadedMetadata);
        video.addEventListener('timeupdate', handleTimeUpdate);*/

        // Attach keydown event listeners for play/pause and mute/unmute


        /*const handleScroll = (event) => {
            if (!video.paused) return; // Do nothing if video is playing
            event.preventDefault(); // Prevent page from scrolling
            const scrollSpeed = 0.001; // Adjust this value to control scrolling speed
            const newTime = video.currentTime + event.deltaY * scrollSpeed;

            // Ensure the new time is within the video's duration
            if (newTime >= 0 && newTime <= video.duration) {
                video.currentTime = newTime;
            }

            // Check if video is playing, if not then pause and manage delayed play
            if (!video.paused && !goingToPlayRef.current) {
                video.pause();
                setIsPaused(true);
                goingToPlayRef.current = true;
                setTimeout(() => {
                    goingToPlayRef.current = false;
                    if (video.paused) {
                        video.play().catch(error => {
                            console.error("Error trying to play the video: ", error);
                        });
                        setIsPaused(false);
                    }
                }, 1000); // Delay play by 1000ms (1 second)
            }
        };*/


    }, []);

    return (
        <div className="background-container">
            {!isMobile ? (
                <video
                    className="background-video"
                    ref={videoRef}
                    muted
                    autoPlay
                    loop
                    playsInline
                >
                    <source src={`${process.env.PUBLIC_URL}/videos/words.mp4`} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            ) : (
                <div className="mobile-videos">
                    <video
                        className="background-video"
                        ref={videoRef}
                        muted
                        autoPlay
                        loop
                        playsInline
                    >
                        <source src={`${process.env.PUBLIC_URL}/videos/mobilewords.mp4`} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            )}

            {/*{!soundOn && (
                <div className="mute-overlay">
                    Turn sound on?
                    <div className='mute-choice' onClick={() => { videoRef.current.muted = false; setSoundOn(true); }}>Yes</div>
                    <div className='mute-choice' onClick={() => setSoundOn(true)}>No</div>
                </div>
            )}
            {isPaused && (
                <div className="video-controls">
                    <span>{Math.round(currentTime * 10) / 10}s / {Math.round(duration * 10) / 10}s  |   Space: Pause/Play  |  M: Mute/Unmute  |   Scroll: Seek </span>
                </div>
            )}*/}
        </div>
    );
}

export default BackgroundVideo;
